import React from 'react';
import { Box } from '../Box';
import { CheckListItem } from '../CheckListItem';

export function CheckListItemGroup({ items = [] }, ...props) {
    return (
        <Box pt="spacing" as="ul" {...props}>
            {items.map(highlight => (
                <CheckListItem key={highlight.title} title={highlight.title} as="li" >
                    {highlight.description}
                </CheckListItem>
            ))}
        </Box>
    );
}
