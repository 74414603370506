import { Layout } from 'components/Layout';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql } from 'gatsby';
import React from 'react';
import { ButtonGroup } from 'src/components/ButtonGroup';
import { CheckListItemGroup } from 'src/components/CheckListItemGroup';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { StatBox } from 'src/components/Methods/metrics/ClientHighlight';
import { ClientProgressHighlight } from 'src/components/Methods/optimization/ClientProgressHighlight';
import { PrincipleBenefits } from 'src/components/Principle/Benefits';
import { PrincipleHero } from 'src/components/Principle/Hero';

export default function OptimizationPage({ data }) {
    return (
        <Layout
            pageTitleFull="Optimization: An Audentio Community Principle"
            siteDescription="Small configuration decisions that affect the speed, utility, and usability of your website can have a real world impact on your KPIs. We'll help you optimize."
            contactForm
            subnavTitle="Optimization"
            subnav={[
                {
                    id: 'features',
                    label: 'Features',
                },
                {
                    id: 'audits',
                    label: 'Audits',
                },
                {
                    id: 'benefits',
                    label: 'Benefits',
                },
                {
                    id: 'reporting',
                    label: 'Reporting',
                },
            ]}
        >
            <PrincipleHero
                title="Improve speeds and satisfy users"
                subtitle="Optimization"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="optimization" />

            <PrincipleFeatures
                id="features"
                title="polish your community with a technical focus"
                features={[
                    {
                        subtitle: 'search',
                        title: 'search rankings',
                        content:
                            'Stay tuned to how well your community is found and utilize tools and best practices to improve.',
                    },
                    {
                        subtitle: 'performance',
                        title: 'comprehensive audit report',
                        content:
                            'Get reports that cover performance over time from multiple sources with exceptional detail and relevance.',
                    },
                    {
                        subtitle: 'access',
                        title: 'improve accessibility',
                        content:
                            'Accessibility is critical in the modern web. Aim to create a complete and positive experience for everyone.',
                    },
                    {
                        subtitle: 'search',
                        title: 'enhance content discoverability',
                        content:
                            'Help your visitors find exactly what they want with minimal frustration.',
                    },
                    {
                        subtitle: 'performance',
                        title: 'increase speed and overall performance',
                        content:
                            'Load content with speed and efficiency and ensure that critical interactions are extremely responsive.',
                    },
                    {
                        subtitle: 'reporting',
                        title: 'monitor metrics moving forward',
                        content:
                            'More than just clicks: Understand how your users find, use, and come back to your community.',
                    },
                ]}
            />

            {/*
            <CTA
                title="Ready to build a community?"
                buttonText="Contact us"
                href="/contact?source=optimization"
            /> */}

            {/* <FeatureHighlight
                id="audits"
                subtitle="Performance"
                image={data.audit.childImageSharp.fluid}
                title="Automated audit tool to identify common pitfalls"
                highlights={[
                    {
                        title: 'hands-free approach',
                        content:
                            'Have your audits compiled for you automatically.',
                    },
                    {
                        title: 'recommended improvements',
                        content:
                            'Easily discover what is critical with audits that highlight areas of improvement.',
                    },
                ]}
            /> */}
            <ClientProgressHighlight
                title="MacRumors website and community"
                description="The project involved rebuilding the site with a more modern library to improve performance and consistency. After the project, a performance test showed that performance increased 129%. Along with performance, user activity also increased. "
                link="https://www.audent.io/case-study/macrumors/full"
                linkText="View Case Study"
                logo={data.macrumorsLogo.childImageSharp.fluid}
                image={data.beforeMetric.childImageSharp.fluid}
                secondImage={data.afterMetric.childImageSharp.fluid}
                imageTitle="Before the upgrade"
                secondImageTitle="After the upgrade"
                pt={[0, 0, 0]}
                alt="image showing the performance metrics before the upgrade"
                secondAlt="image showing the performance metrics after the upgrade"
            >
                <StatBox
                    title="39%"
                    subtitle="Increase in"
                    kind="User registrations"
                />
                <StatBox
                    title="16%"
                    subtitle="Increase in"
                    kind="Daily average users active"
                />
                <StatBox
                    title="27%"
                    subtitle="Increase in"
                    kind="Number of posts made"
                />
            </ClientProgressHighlight>

            <PrincipleBenefits
                id="benefits"
                mt={0}
                title="How engagement can benefit your community"
                // ctaHref="/contact?source=optimization"
                // ctaLabel="Contact us"
                benefits={[
                    'improve the speed, utility, and usability of the platform',
                    'ensure your community meets search engine recommendations and requirements',
                    'implement modern functionality improvements',
                ]}
            />

            <MediaSection
                videoId="metrics-video-optimization"
                video="/videos/methods/metrics/data.mp4"
                title="Get a detailed picture of your community"
                flipped
            >
                <CheckListItemGroup
                    items={[
                        {
                            title: 'measure',
                            description:
                                'Know how your community is doing with key performance indicators.',
                        },
                        {
                            title: 'learn',
                            description:
                                'Understand how past actions have impacted the community.  plan for your future with metrics to help measure your community and give actionable insights.',
                        },
                        {
                            title: 'plan',
                            description:
                                'Utilize metrics to help identify actionable insights and make decisions.',
                        },
                    ]}
                />
                <ButtonGroup
                    buttons={[
                        {
                            text: 'LEARN MORE',
                            href: '/methods/metrics',
                        },
                    ]}
                />
            </MediaSection>
            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Related articles"
                sectionHeaderProps={{
                    direction: 'row',
                }}
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                showContact={false}
                items={[
                    {
                        image: data.accessible.childImageSharp.fluid,
                        heading:
                            'Things you may not have thought of to make your community as accessible as possible',
                        content:
                            'If you aren’t thinking about website accessibility, you should be. Everyone should have at least a basic level of accessibility enabled on...',
                        href:
                            'https://adjourn.audent.io/things-you-may-not-have-thought-of-to-make-your-community-as-accessible-as-possible-34b9dffeda74',
                    },
                    {
                        image: data.forumStructure.childImageSharp.fluid,
                        heading:
                            'Organizing your community topics and forum structure',
                        content:
                            'The space you provide for discussions is a critical part of your community’s strength as a platform. Creating too many separate topics causes confusion...',
                        href:
                            'https://adjourn.audent.io/organizing-your-community-topics-and-forum-structure-b49b1a312207',
                    },
                    {
                        image: data.optimizing.childImageSharp.fluid,
                        heading: 'Optimizing for XenForo',
                        content:
                            'Throughout our history of working with community platforms, we’ve worked to optimize the performance of websites and improve the user experience.',
                        href:
                            'https://adjourn.audent.io/optimization-and-speed-improvements-to-xenforo-30c2a3f45741',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query OptimizationQuery {
        hero: file(relativePath: { eq: "images/hero/optimization.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        audit: file(
            relativePath: { eq: "images/optimization/audit-tool.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        reporting: file(
            relativePath: { eq: "images/optimization/reporting-dash.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        optimizing: file(
            relativePath: { eq: "images/optimization/optimizing.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        accessible: file(
            relativePath: { eq: "images/optimization/accessible.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        forumStructure: file(
            relativePath: { eq: "images/optimization/forum-structure.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        beforeMetric: file(
            relativePath: {
                eq: "images/optimization/optimize-metric-graph-before.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        macrumorsLogo: file(
            relativePath: { eq: "images/optimization/macrumors-logo.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        afterMetric: file(
            relativePath: {
                eq: "images/optimization/optimize-metric-graph-after.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
