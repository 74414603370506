import { Grid } from '@audentio/stuff/src';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import {
    GridSection,
    GridSectionLeft,
    GridSectionRight
} from 'components/GridSection';
import { Heading } from 'components/Heading';
import Img from 'gatsby-image';
import React from 'react';
import { Button } from 'src/components/Button';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';

const ImageContainer = ({ image, alt, title }) => (
    <Flex
        direction="column"
        alignItems={{ _: 'flex-start', lg: 'center' }}
        pt={['spacing', 'spacing-lg', '40px']}
        w="100%"
        maxW={500}
    >
        {title && (
            <Heading alignSelf={{ _: 'center' }} as="h6" kind="h6" pt={0}>
                {title}
            </Heading>
        )}
        <Box
            w="100%"
            m={{ _: 0, lg: 'spacing-sm' }}
            boxShadow="0 5px 11px 0 rgba(0,0,0,0.15)"
        >
            <Img
                style={{
                    width: '100%',
                    height: '100%',
                }}
                fluid={image}
                alt={alt}
            />
        </Box>
    </Flex>
);

export const ClientProgressHighlight = ({
    image,
    imageTitle,
    secondImage,
    secondImageTitle,
    secondAlt,
    children,
    logo,
    title,
    description,
    link,
    linkText,
    mediaProps = {},
    contentProps = {},
    gridSectionProps = {},
    alt,
    ...props
}: any) => {
    return (
        <Section wrap vertical="lg" bg="secondaryBackground" {...props}>
            <GridSection
                alignItems={{
                    _: 'flex-start',
                    md: 'center',
                }}
                pt={{ _: 'spacing-xl', lg: 0 }}
                {...gridSectionProps}
            >
                <GridSectionLeft px={{ _: 0, lg: 32 }} {...contentProps}>
                    <Flex direction="column">
                        {logo && (
                            <Box h="60px" maxH="60px" mb="spacing">
                                <Img
                                    fixed={logo}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    imgStyle={{
                                        width: 'auto',
                                        height: '100%',
                                        maxWidth: '200px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                        )}
                        {title && (
                            <Heading kind="h5" mt={0} mb="spacing">
                                {title}
                            </Heading>
                        )}

                        {description && (
                            <Paragraph fontSize="body" color="bodyText">
                                {description}
                            </Paragraph>
                        )}

                        {link && (
                            <Button
                                href={link}
                                kind="primary"
                                mt="spacing-lg"
                                maxWidth="200px"
                            >
                                {linkText}
                            </Button>
                        )}

                        {children && (
                            <Grid
                                d={{
                                    _: 'grid',
                                    lg: 'none',
                                }}
                                columns={{
                                    _: 2,
                                    md: 3,
                                }}
                                pt="spacing-xl"
                                spacing={{
                                    _: 'spacing-lg',
                                    lg: 'spacing-xl',
                                }}
                                w={{
                                    _: '100%',
                                }}
                            >
                                {children}
                            </Grid>
                        )}
                    </Flex>
                </GridSectionLeft>
                <GridSectionRight
                    hasMedia
                    px={0}
                    maxWidth={{ _: '100%', lg: '66%' }}
                    {...mediaProps}
                >
                    <Grid
                        columns={{
                            _: 1,
                            lg: 2,
                        }}
                        pt="spacing-xl"
                        spacing={{
                            _: 'spacing-lg',
                            lg: 'spacing-xl',
                        }}
                        w={{
                            _: '100%',
                        }}
                    >
                        <ImageContainer
                            image={image}
                            alt={alt}
                            title={imageTitle}
                        />
                        <ImageContainer
                            image={secondImage}
                            alt={secondAlt}
                            title={secondImageTitle}
                        />
                    </Grid>
                    {children && (
                        <Grid
                            d={{
                                _: 'none',
                                lg: 'grid',
                            }}
                            columns={{
                                _: 1,
                                md: 2,
                                lg: 3,
                            }}
                            pt="spacing-xl"
                            spacing={{
                                _: 'spacing-lg',
                                lg: 'spacing-xl',
                            }}
                            w={{
                                _: '100%',
                            }}
                        >
                            {children}
                        </Grid>
                    )}
                </GridSectionRight>
            </GridSection>
        </Section>
    );
};
